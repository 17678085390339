import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, SwipeableDrawer, Tooltip,  } from '@mui/material/'
import {  SWAP_CURRENCIES_NAMES, SWAP_CURRECIES_LOGO, ADM_PRICE, SWAP_DIRECTIONS_DIRECT, SWAP_DIRECTIONS_REVERS, OWNER_ADDRESS, ADM_DIGITALS } from '../../lib/vars'
import { admSendTransactionData, getAllBalances, getPrice, getTransaction, sendAssets, sendContact, usdtSendTransactionData } from '../../lib/requests'
import { useTonAddress, useTonConnectModal, useTonConnectUI } from '@tonconnect/ui-react'
import Decimal from 'decimal.js'

declare global {
  interface Window {
    Telegram:any;
  }
}

export function Exchanger() {
      const [openFrom, setOpenFrom] = useState<boolean>(false)
      const [openTo, setOpenTo] = useState<boolean>(false)
      const [fromCurrency, setFromCurrency] = useState<string>('usdt')
      const [nftAddress, setNftAddress] = useState<string>("")
      const [toCurrency, setToCurrency] = useState<string>('adm')
      const [tonPrice, setTonPrice] = useState<number>()
      const [admPrice, setAdmPrice] = useState<number>(ADM_PRICE)
      const { state, open, close } = useTonConnectModal();
      const walletAddress = useTonAddress()
      const [tonConnectUI, setOptions] = useTonConnectUI()
      const [valueFrom, setValueFrom] = useState<string>("")
      const [valueTo, setValueTo] = useState<string>("")
      const [tonWalletBalance, setTonWalletBalance] = useState<number>(0)
      const [admWalletBalance, setAdmWalletBalance] = useState<number>(0)
      const [usdtWalletBalance, setUsdtWalletBalance] = useState<number>(0)
      const [buyButton, setBuyBotton] = useState<string>("Введите количество")
      const [exchangeStatus, setExchangeStatus] = useState<string>("")
      const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches

      const [status, setStatus] = useState<number>(1)
      const [contact, setContact] = useState<string | undefined>()

      let tg = window.Telegram.WebApp;

      useEffect(() => {          
        getPrice()
                .then((price) => {
                  if(price) {
                    const admPriceInTon = Number((1/(price * admPrice)).toFixed(10))
                    setTonPrice(admPriceInTon)                    
                  }
                  
                })
                .catch((err) => {
                  console.log("GET PRICE ERROR: ", err)
                })
      }, [])

      useEffect(() => { 
        if(walletAddress && typeof walletAddress === 'string' && walletAddress !== "") {
          getAllBalances(walletAddress)
                                      .then((response) => {
                                        response.tonBalance && setTonWalletBalance(response.tonBalance)
                                        response.admBalance && setAdmWalletBalance(response.admBalance)
                                        response.usdtBalance && setUsdtWalletBalance(response.usdtBalance)
                                      })
                                      .catch(err => console.log("GET BALANCES ERROR: ", err, " WALLET ADDRESS: ", walletAddress))
        }     
            
      }, [walletAddress])



      function validate(evt: any) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
      }


      const onChoose = (direction: string, currency: string, nftAddress: string = "") => {
          setValueTo('')
          setValueFrom('')
          setBuyBotton("Введите количество")          
          if(direction === 'from') {
            setFromCurrency(currency)
            setOpenFrom(false)
            setToCurrency(SWAP_DIRECTIONS_DIRECT[currency as keyof typeof SWAP_DIRECTIONS_DIRECT])
          } else if(direction === 'to') {
            setToCurrency(currency)
            setOpenTo(false)
            setFromCurrency(SWAP_DIRECTIONS_REVERS[currency as keyof typeof SWAP_DIRECTIONS_REVERS])
          } else if(direction === "nul") {
            setStatus(2)
            setToCurrency('nul')
            setOpenTo(false)
          }

      }



      const fromInner = () => (
            <div className='main-dialog-choose-currency-window'>
              <div className='puller'></div>
              <DialogTitle className='title'>Выберите актив</DialogTitle>
              <div className='list'>
                <div className='list-button' onClick={() => onChoose('from', 'usdt')}>
                  <div className='symbol'>
                    <div className='avatar'>
                        <img
                          className='img'
                          alt=''
                          src={SWAP_CURRECIES_LOGO['usdt']}
                        />
                    </div>
                    <div className='name'>{SWAP_CURRENCIES_NAMES['usdt']}</div>
                  </div>                      
                  <div className='amount'>{usdtWalletBalance.toFixed(2)}</div>
                </div>
                <div className='list-button' onClick={() => onChoose('from', 'adm')}>
                  <div className='symbol'>
                    <div className='avatar'>
                        <img
                          className='img'
                          alt=''
                          src={SWAP_CURRECIES_LOGO['adm']}
                        />
                    </div>
                    <div className='name'>{SWAP_CURRENCIES_NAMES['adm']}</div>
                  </div>                      
                  <div className='amount'>{admWalletBalance.toFixed(0)}</div>
                </div>
                {/*renderNfts()*/}
              </div>
          </div>
      )
      

      const chooseCurrencyFrom = () => {
        const handleClose = () => {
          setOpenFrom(false)
        }
        const toggleDrawer =
          (open: boolean) =>
          (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
              event &&
              event.type === 'keydown' &&
              ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
            ) {
              return;
            }

            setOpenFrom(true)
        };
          return (
            <>
            {isMobile? 
              <SwipeableDrawer
                  onClose={handleClose} 
                  open={openFrom}
                  className='choose-currency-modal-dialog'
                  anchor='bottom'
                  onOpen={toggleDrawer(true)}
              >
                {fromInner()}  
              </SwipeableDrawer>
              :
              <Dialog 
                //disableScrollLock
                onClose={handleClose} 
                open={openFrom}
                className='choose-currency-modal-dialog'
              >
                {fromInner()}                
              </Dialog>
            }
            </>
          )
      }

      const toInner = () => (
        <div className='main-dialog-choose-currency-window'>
          <div className='puller'></div>
          <DialogTitle className='title'>Ընտրեք ակտիվ</DialogTitle>
            <div className='list'>
              <div className='list-button' onClick={() => onChoose('to', 'ton')}>
                <div className='symbol'>
                  <div className='avatar'>
                      <img
                        className='img'
                        alt=''
                        src={SWAP_CURRECIES_LOGO['ton']}
                      />
                  </div>
                  <div className='name'>{SWAP_CURRENCIES_NAMES['ton']}</div>
                </div>                      
                <div className='amount'></div>
              </div>
              <div className='list-button' onClick={() => onChoose('to', 'usdt')}>
                <div className='symbol'>
                  <div className='avatar'>
                      <img
                        className='img'
                        alt=''
                        src={SWAP_CURRECIES_LOGO['usdt']}
                      />
                  </div>
                  <div className='name'>{SWAP_CURRENCIES_NAMES['usdt']}</div>
                </div>                      
                <div className='amount'></div>
              </div>
              <div className='list-button' onClick={() => onChoose('to', 'adm')}>
                <div className='symbol'>
                  <div className='avatar'>
                      <img
                        className='img'
                        alt=''
                        src={SWAP_CURRECIES_LOGO['adm']}
                      />
                  </div>
                  <div className='name'>{SWAP_CURRENCIES_NAMES['adm']}</div>
                </div>                      
                <div className='amount'></div>
              </div>
              <div className='list-button' onClick={() => onChoose('nul', 'nul')}>
                <div className='symbol'>
                  {/*<div className='avatar'>
                      <img
                        className='img'
                        alt=''
                        src={SWAP_CURRECIES_LOGO['adm']}
                      />
                  </div>*/}
                  <div className='name'>Наличные</div>
                </div>                      
                <div className='amount'></div>
              </div>
            </div>
        </div>
      )

      const chooseCurrencyTo = () => {
        const handleClose = () => {
          setOpenTo(false)
        }
        const toggleDrawer =
          (open: boolean) =>
              (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                  event &&
                  event.type === 'keydown' &&
                  ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
                ) {
                  return;
                }

                setOpenTo(true)
        };
          return (
            <>
              {isMobile? 
                <SwipeableDrawer
                    onClose={handleClose} 
                    open={openTo}
                    className='choose-currency-modal-dialog'
                    anchor='bottom'
                    onOpen={toggleDrawer(true)}
                >
                  {toInner()}  
                </SwipeableDrawer>
              :
                <Dialog 
                  //disableScrollLock
                  onClose={handleClose} 
                  open={openTo}
                  className='choose-currency-modal-dialog'
                >
                  {toInner()} 
                </Dialog>
              }
            </>
          )
      }


      const onChangeFrom = async(amount: string) => {
          if(amount[0] === '0' && amount[1] !== '.') {
            amount = amount.slice(1)
          }          
          const reg = /^[^.]*\.?[^.]*$/g
          if(!amount.match(reg)) {
            amount = amount.replaceAll(/./gi, "")
          }
          if(amount === "") {
            amount="0"
          }
          const amountFrom = parseFloat(amount)          
          if(fromCurrency === 'usdt' && admPrice) {
            setValueFrom(amount)
            const amountADMforTon = (amountFrom * admPrice).toFixed(2)
            setValueTo(amountADMforTon)
            if(amountFrom > usdtWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (amountFrom <= usdtWalletBalance && amountFrom > 0) {
              setBuyBotton("Купить")
            } else if (amountFrom === 0) {
              setBuyBotton("Введите количество")
            }
          } else if(fromCurrency === 'adm' && toCurrency === 'ton' && tonPrice) {
            setValueFrom(amount)
            const amountADMforTon = (amountFrom * tonPrice).toFixed(2)
            setValueTo(amountADMforTon)
            if(amountFrom > admWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (amountFrom <= admWalletBalance && amountFrom > 0) {
              setBuyBotton("Купить")
            } else if (amountFrom === 0) {
              setBuyBotton("Введите количество")
            }
          } else if(fromCurrency === 'adm' && toCurrency === 'usdt') {
            setValueFrom(amount)
            const amountADMforTon = (amountFrom / admPrice).toFixed(2)
            setValueTo(amountADMforTon)
            if(amountFrom > admWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (amountFrom <= admWalletBalance && amountFrom > 0) {
              setBuyBotton("Купить")
            } else if (amountFrom === 0) {
              setBuyBotton("Введите количество")
            }
          }
      }

      const onChangeTo = async(amount: string) => {
          if(amount[0] === '0' && amount[1] !== '.') {
            amount = amount.slice(1)
          }          
          const reg = /^[^.]*\.?[^.]*$/g
          if(!amount.match(reg)) {
            amount = amount.replaceAll(/./gi, "")
          }
          if(amount === "") {
            amount="0"
          }
          const amountTo = parseFloat(amount)          
          if(toCurrency === 'ton' && tonPrice) {
            setValueTo(amount)
            const tempAmount: number = (amountTo / tonPrice)
            const amountADMforTon: string = tempAmount.toFixed(2)
            setValueFrom(amountADMforTon)
            if(tempAmount > admWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (tempAmount <= admWalletBalance && tempAmount > 0) {
              setBuyBotton("Купить")
            } else if (tempAmount === 0) {
              setBuyBotton("Введите количество")
            }
          } else if(toCurrency === 'usdt') {
            setValueTo(amount)
            const tempAmount: number = (amountTo * admPrice)
            const amountADMforTon: string = tempAmount.toFixed(2)
            setValueFrom(amountADMforTon)
            if(tempAmount > admWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (tempAmount <= admWalletBalance && tempAmount > 0) {
              setBuyBotton("Купить")
            } else if (tempAmount === 0) {
              setBuyBotton("Введите количество")
            }
          } else if(toCurrency === 'adm') {
            setValueTo(amount)
            const tempAmount: number = (amountTo / admPrice)
            const amountADMforTon: string = tempAmount.toFixed(2)
            setValueFrom(amountADMforTon)
            if(tempAmount > usdtWalletBalance) {
              setBuyBotton("Недостаточно средств")
            } else if (tempAmount <= usdtWalletBalance && tempAmount > 0) {
              setBuyBotton("Купить")
            } else if (tempAmount === 0) {
              setBuyBotton("Введите количество")
            }
          }
      }

      



      const exchangerFrom = () => {
        return (
          <div className='from'>
              <div className='title'>Ուղարկել</div>
              <div className='choose-btn' onClick={() => setOpenFrom(true)}>
                <div className='tick'>
                    {TICK_ICON}
                </div>
                <div className='currency'>
                  {SWAP_CURRENCIES_NAMES[fromCurrency as keyof typeof SWAP_CURRENCIES_NAMES]}
                </div>
                <div className='currency-icon'>
                  <img
                    className='img'
                    alt=''
                    src={SWAP_CURRECIES_LOGO[fromCurrency as keyof typeof SWAP_CURRECIES_LOGO]}
                  />
                </div>
                
              </div>
              
                <div className='input-div'>
                  <input type='text' name='amount' className='input' inputMode='numeric' placeholder='0' onKeyPress={validate} value={valueFrom} onChange={(e) => onChangeFrom(e.target.value)} />
                </div>              
              <div id="currency" className='dialog'>
                  {chooseCurrencyFrom()}
              </div>
          </div>
        )
      }
      const exchangerTo = () => {
        return (
          <div className='from'>
                <div className='title'>Ստացեք</div>
                    <div className='choose-btn' onClick={() => setOpenTo(true)}>
                      <div className='tick'>
                          {TICK_ICON}
                      </div>
                      <div className='currency'>
                      {SWAP_CURRENCIES_NAMES[toCurrency as keyof typeof SWAP_CURRENCIES_NAMES]}
                      </div>
                      {status === 1 && <div className='currency-icon'>
                        <img
                          className='img'
                          alt=''
                          src={SWAP_CURRECIES_LOGO[toCurrency as keyof typeof SWAP_CURRECIES_LOGO]}
                        />
                      </div>}
                      
                    </div>                    
                      <div className='input-div'>
                        <input type='text' name='amount' className='input' inputMode='numeric' placeholder='0' onKeyPress={validate} value={valueTo} onChange={(e) => onChangeTo(e.target.value)} />
                      </div>                    
                    <div id="currency" className='dialog'>
                        {chooseCurrencyTo()}
                </div>
          </div>
        )
      }

      const onSetContact = async(str: string) => {
        setContact(str)
        setBuyBotton("Отправить")
      }

      const contactForm = () => {
        return (
          <div className='contact-form'>
                <div className='title'>Отправьте телеграм юзернейм</div>                                     
                      <div className='input-div'>
                        <input type='text' name='amount' className='input' inputMode='numeric' placeholder='Телеграм' value={contact} onChange={(e) => onSetContact(e.target.value)} />
                      </div>
          </div>
        )
      }
      
      const exchangerMiddle = () => {
        return (
          <div className='middle'>
                <div className='line'>
                <div className='icon-btn'>
                  <div className='arrow'>
                    {ARROW_ICON}
                  </div>
                </div>
                </div>
          </div>
        )
      }

      const PriceView = () => {
        const approx = <span>&asymp;</span>
        const exact = <span>=</span>
        const isApprox: boolean = SWAP_CURRENCIES_NAMES[toCurrency as keyof typeof SWAP_CURRENCIES_NAMES] === 'TON'
        const isAdm: boolean = SWAP_CURRENCIES_NAMES[toCurrency as keyof typeof SWAP_CURRENCIES_NAMES] === 'ADM'
        const isUsdt: boolean = SWAP_CURRENCIES_NAMES[toCurrency as keyof typeof SWAP_CURRENCIES_NAMES] === 'USDT'
        let amountFrom: string | undefined = undefined
        let amountTo: string | undefined = (1).toFixed(0)
        if(isApprox && tonPrice) {
          amountFrom = (1 / tonPrice).toFixed(2)
        } else if(isAdm) {
          amountFrom = (1/admPrice).toFixed(4)
        } else if(isUsdt) {
          amountFrom = admPrice.toFixed(0)
        }
        return(
          <div className='price-section'>
              <div className='status-area'>{exchangeStatus}</div>
              <div className='price-area'> 
              { amountFrom &&
                <div><span>{ amountTo + " " + SWAP_CURRENCIES_NAMES[toCurrency as keyof typeof SWAP_CURRENCIES_NAMES] }</span> { isApprox?approx:exact } <span>{ " " + amountFrom + " " + SWAP_CURRENCIES_NAMES[fromCurrency as keyof typeof SWAP_CURRENCIES_NAMES] }</span></div>
              }
              </div>
          </div>
        )
      }

      const onSendContact = async() => {
        if(!contact || contact?.trim().length === 0) {
          setBuyBotton("Invalid contact")
        } else {
          await sendContact(contact)
          setStatus(1)
          setToCurrency('adm')
        }
        
      }

      const onSetStatus3 = async() => {
        setStatus(3)
        setBuyBotton("Отправить")
      }

      const onConnect = () => {
        if(!walletAddress) {
          open()
        }
        
      }
      
      const onBuy = async() => {
        if(walletAddress && buyButton === "Купить" && Number(valueFrom) > 0) {
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 180,
            messages: Array(),
          }
          if(toCurrency === 'ton') {
            const digitals = (10 ** ADM_DIGITALS).toString()
            const tons: Decimal = new Decimal(digitals).times(new Decimal(valueFrom))
            const dataForTransaction = await admSendTransactionData(walletAddress, valueFrom)
            transaction.messages.push({
                              address: dataForTransaction.jettonWalletAddress.toString(),
                              amount: '100000000',
                              payload: dataForTransaction.payload.toBoc().toString("base64")
            })

          } else if(toCurrency === 'usdt') {
            const digitals = (10 ** ADM_DIGITALS).toString()
            const tons: Decimal = new Decimal(digitals).times(new Decimal(valueFrom))
            const dataForTransaction = await admSendTransactionData(walletAddress, valueFrom)
            transaction.messages.push({
                              address: dataForTransaction.jettonWalletAddress.toString(),
                              amount: '100000000',
                              payload: dataForTransaction.payload.toBoc().toString("base64")
            })
          } else if(toCurrency === 'adm') {
              const digitals = (10 ** ADM_DIGITALS).toString()
              const tons: Decimal = new Decimal(digitals).times(new Decimal(valueFrom))
              const dataForTransaction = await usdtSendTransactionData(walletAddress, valueFrom)
              transaction.messages.push({
                                address: dataForTransaction.jettonWalletAddress.toString(),
                                amount: '100000000',
                                payload: dataForTransaction.payload.toBoc().toString("base64")
              })
          }
          try {
            const result = await tonConnectUI.sendTransaction(transaction, {
                  modals: ['before', 'success', 'error'],
                  notifications: ['before', 'success', 'error']
              });
            const someTxData = await getTransaction(result.boc);
            if(someTxData && someTxData.length > 0) {
              setExchangeStatus("Ваши активы отправлены успешно")
              const type: number = toCurrency === 'ton'? 1: (toCurrency === 'usdt'? 2: 3)
              const amount: number = parseFloat(valueTo)
              
              const sentAssets = await sendAssets(walletAddress, type, amount, someTxData)
              console.log("SENT: ", sentAssets)
              if(sentAssets) setExchangeStatus("Обмен произведен")                            
              if(sentAssets) {
                getAllBalances(walletAddress)
                                  .then((response) => {
                                    response.tonBalance && setTonWalletBalance(response.tonBalance)
                                    response.admBalance && setAdmWalletBalance(response.admBalance)
                                    response.usdtBalance && setUsdtWalletBalance(response.usdtBalance)
                                  })
                                  .catch(err => console.log("GET BALANCES ERROR: ", err, " WALLET ADDRESS: ", walletAddress))
              }
            }

          } catch (e) {
              console.error("SEND TRANSACTION ERROR: ", e);
          }
        }        
      }



      return (
        <div className='exchanger'>
          {/*<div id='exchanger' className='title-main'>Обменник</div>*/}
          <div className='exchanger-main-section'>
            <div className="exchanger-direction">
                  {(status === 1 || status === 2) && exchangerFrom()}
                  {(status === 1 || status === 2) && exchangerMiddle()}
                  {(status === 1 || status === 2) && exchangerTo()}
                  {(status === 1 || status === 2) && PriceView()}
                  {status === 3 && contactForm()}
            </div>
            {status === 1 && (
              walletAddress ?
                <div className={buyButton === "Купить"? 'btn': 'btn passive'} onClick={onBuy}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>{buyButton}</div></div>
                :
                <div className='btn' onClick={onConnect}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>Connect Wallet</div></div>
              )
            }
            {status === 2 &&              
              <div className='btn' onClick={() => onSetStatus3()}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>Отправить контакт</div></div>
            }
            {status === 3 &&              
              <div className='btn' onClick={() => onSendContact()}><div className='ton-icon'>{TON_ICON}</div><div className='connect'>{buyButton}</div></div>
            }
          </div>
            
        </div>
      );
}






const TON_ICON = <svg>
    <path
    d="M12.78 0H3.22C1.46 0 .346 1.896 1.231 3.43l5.9 10.227c.385.667 1.35.667 1.735 0L14.77 3.429C15.652 1.9 14.538 0 12.781 0ZM7.127 10.59 5.842 8.103l-3.1-5.546a.542.542 0 0 1 .476-.81h3.908v8.844l.001-.001Zm6.128-8.034-3.1 5.548-1.284 2.486V1.746h3.908c.428 0 .68.455.476.81Z"
    viewBox='0 0 16px 15px'
    fill="#fff"
    width="14" height="14"
    preserveAspectRatio="none"
    className='.svg'
    />
    </svg>

const ARROW_ICON = <svg>
<path
d="M8.026 0.734 c 0.231 0 0.419 0.07 0.562 0.213 a 0.734 0.734 0 0 1 0.223 0.556 v 9.893 l -0.09 2.424 l -0.488 -0.205 l 2.712 -2.964 l 1.778 -1.728 a 0.657 0.657 0 0 1 0.248 -0.156 a 0.803 0.803 0 0 1 0.306 -0.057 a 0.733 0.733 0 0 1 0.753 0.745 a 0.791 0.791 0 0 1 -0.248 0.565 l -5.17 5.126 a 0.743 0.743 0 0 1 -0.272 0.189 a 0.771 0.771 0 0 1 -0.62 0 a 0.743 0.743 0 0 1 -0.273 -0.189 L 2.27 10.02 a 0.804 0.804 0 0 1 -0.24 -0.565 c 0 -0.213 0.069 -0.39 0.207 -0.532 a 0.743 0.743 0 0 1 0.545 -0.213 c 0.11 0 0.213 0.019 0.306 0.057 a 0.657 0.657 0 0 1 0.249 0.156 l 1.778 1.728 l 2.696 2.964 l -0.472 0.205 l -0.09 -2.424 V 1.503 a 0.75 0.75 0 0 1 0.214 -0.556 a 0.765 0.765 0 0 1 0.563 -0.213 Z"
viewBox='0 0 16 16'
fill="#9043c3"
/>
</svg>


const TICK_ICON = <svg className='tick-icon'>
  <path stroke="currentColor" d="m4 6 4 4 4-4"></path>
</svg>
